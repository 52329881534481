import React, { useReducer } from "react";

import MenuIcon from "@/assets/svg/menu.svg";
import { MenuIconWrapper } from "./styled";
import MobileModal from "./MobileModal";

function Mobile() {
  const [isOpen, toggleMenu] = useReducer((state: boolean) => !state, false);

  return (
    <>
      <MenuIconWrapper onClick={toggleMenu}>
        <MenuIcon />
      </MenuIconWrapper>

      <MobileModal onClose={toggleMenu} isOpen={isOpen} />
    </>
  );
}

export default Mobile;
