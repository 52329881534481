import { navigate } from "gatsby";

const useNavigation = () => {
  const onNavigate =
    (sectionId: string, offset = 0) =>
    (e: any) => {
      if (!sectionId.startsWith("#") || sectionId.length <= 1) return;

      e.preventDefault();

      const section = document.querySelector(sectionId) as HTMLDivElement;

      // TODO: redirect to home page
      if (!section) {
        navigate(`/${sectionId}`);
        return;
      }

      const sectionY = section.offsetTop;

      window.scrollTo({
        top: sectionY - offset,
        left: 0,
        behavior: "smooth",
      });
    };

  return onNavigate;
};

export default useNavigation;
