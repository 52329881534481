import React from "react";
import { Link } from "gatsby";
import {
  MobileModalWrapper,
  MobileModalHeader,
  MobileMenuContainer,
  MobileMenuFooter,
} from "./styled";

import CloseIcon from "@/assets/svg/close.svg";
import WhatsApp from "@/assets/svg/whatsup.svg";
import MenuIcon from "@/assets/svg/menu-modal.svg";
import Typography from "@/components/Typography";
import links from "../../links";
import Button from "@/components/Button/Button";
import useNavigation from "../../hooks/useNavigation";

interface IMobileModalProps {
  isOpen?: boolean;
  onClose: () => void;
}

function MobileModal({ isOpen, onClose }: IMobileModalProps) {
  const onNavigate = useNavigation();

  const handleMenuItemClick = (to: string, offset?: number) => (e: any) => {
    onClose();
    onNavigate(to, offset)(e);
  };

  return (
    <MobileModalWrapper visible={isOpen} role="navigation">
      <MobileModalHeader>
        <MenuIcon />

        <CloseIcon onClick={onClose} />
      </MobileModalHeader>

      <MobileMenuContainer>
        {links.map(({ label, to, offset }) => (
          <li key={label}>
            <Typography
              onClick={handleMenuItemClick(to, offset)}
              align="center"
              weight={700}
              as={Link}
              to={to}
            >
              {label}
            </Typography>
          </li>
        ))}
      </MobileMenuContainer>

      <MobileMenuFooter>
        <Button variant="light">
          <WhatsApp /> Entrar em contato
        </Button>
      </MobileMenuFooter>
    </MobileModalWrapper>
  );
}

export default MobileModal;
