import styled from "@emotion/styled";

export const NavigationContainer = styled.nav`
  display: flex;
  grid-column-gap: min(5vw, 4.6rem);
  align-items: center;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    display: none;
  }

  > a:last-child {
    color: var(--header-button-color);
    background: var(--header-button-background);
  }
`;

export const NavigationItem = styled.li`
  list-style: none;
  position: relative;

  &:hover nav {
    opacity: 1;
    transition-delay: 0s;
    visibility: visible;
    transform: translateY(1.2rem);
  }

  a {
    text-decoration: none;
    padding-bottom: 2px;
    transition: all 0.3s ease-out;
    color: var(--header-logo-theme);

    &:hover {
      all: 0 1px 0 ${({ theme }) => theme.colors.primary};
    }
  }
`;

export const NavigationDropdown = styled.nav`
  position: absolute;
  top: 1.4rem;
  left: -3rem;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s 0.3s ease-in-out;
  padding: 32px 0;
  background: ${({ theme }) => theme.colors.navbar.background};
  min-width: 260px;

  ul {
    margin-bottom: 50px;
  }

  li {
    padding: 0 32px 0.4rem;
    margin-bottom: 0.6rem;
    /* TODO: Move it to theme */
    border-bottom: solid 1px ${({ theme }) => theme.colors.navbar.border};
  }

  ul:last-child,
  li:last-child {
    margin-bottom: 0;
  }

  ul:last-child li:last-child {
    border-bottom: none;
  }
`;

export const NavigationDropdownItem = styled.li`
  a {
    color: ${({ theme }) => theme.colors.navbar.item};
  }
`;
