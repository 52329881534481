import { css } from "@emotion/react";

const globals = css`
  * {
    box-sizing: border-box;
  }

  body,
  html {
    margin: 0;
    padding: 0;
  }

  body,
  html {
    overflow-x: hidden;
  }

  body {
    overflow-y: hidden;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

export default globals;
