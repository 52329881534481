const React = require("react");
const Layout = require("../src/core/Layout").default;
const { lightTheme } = require("../src/theme");
const { ThemeProvider } = require("@emotion/react");

exports.wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return (
    <Layout headerBg={element?.type?.header?.background} {...props}>
      {element}
    </Layout>
  );
};

exports.wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      {element}
    </ThemeProvider>
  );
};
