import { IThemeColors, IBaseColor } from "./types";

const makeColors = ({ primary, light, secondary }: IBaseColor) => ({
  primary,
  light,
  secondary,
  navbar: {
    background: primary,
    border: "#2b354f",
    item: light,
  },
  plans: {
    primary: light,
  },
  topic: {
    text: "#2b354f",
  },
  contact: {
    header: {
      links: light,
      background: "transparent",
      buttonColor: light,
      buttonBg: primary,
    },
    text: light,
  },
  fonts: {
    primary,
    stone: "#bebebe",
    light,
  },
  box: {
    background: light,
    color: primary,
    hover: {
      background: light,
    },
  },
  footer: {
    background: primary,
    color: light,
  },
  button: {
    primary: light,
    primaryBackground: primary,
    secondary: primary,
    secondaryBackground: light,
    lightBackground: light,
    light: primary,
  },
});

export const baseColors: IThemeColors = makeColors({
  primary: "#161C2D",
  light: "#fff",
  secondary:
    "linear-gradient(180deg, #1D8033 0%, #23993E 16.5%, #28A745 45.99%, #219B3D 66.72%, #23973E 100%)",
});

export const withBaseColors = (
  fn: any,
  extendedColors: IThemeColors = baseColors
) => {
  const baseThemeColors = {
    ...extendedColors,
    button: extendedColors.button || baseColors.button,
    fonts: extendedColors.fonts || baseColors.fonts,
    logo: extendedColors,
  };
  const theme = fn(baseThemeColors);

  return theme;
};
