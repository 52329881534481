import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";
import { TypographyBaseProps } from "./types";
import { getVariantStyles } from "./variants";

const Typography = styled("p", {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "uppercase",
})<TypographyBaseProps>`
  font-family: ${({ theme }) => theme.fonts.inter};
  color: ${({ theme, color }) =>
    color ? theme.colors.fonts[color] : theme.colors.fonts.primary};
  margin: 0 0 ${({ paragraph }) => (paragraph ? "1rem" : 0)};
  text-align: ${({ align }) => align || "left"};

  ${getVariantStyles}

  ${({ weight }: TypographyBaseProps) => weight && `font-weight: ${weight};`}
  ${({ italic }: TypographyBaseProps) => italic && `font-style: italic;`}
  ${({ uppercase }: TypographyBaseProps) =>
    uppercase && `text-transform: uppercase;`}

    ${({ topic, theme, color }) =>
    topic &&
    `
      padding-left: 0.5rem;
      border-left: 3px solid ${
        color ? theme.colors.fonts[color] : theme.colors.fonts.primary
      };
      line-height: 20px;
    `}
`;

export default Typography;
