import styled from "@emotion/styled";

export const LayoutWrapper = styled.div`
  padding-top: 7.8rem;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    padding-top: 2rem;
  }
`;
