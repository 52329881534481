const links = [
  {
    label: "Sobre nós",
    to: "#sobre-nos",
    offset: 200,
  },
  {
    label: "Para Você",
    to: "#",
    options: {
      companies: {
        label: "PESSOAS FÍSICAS",
        items: [
          {
            label: "Contabilidade p/ investidores",
            to: "/para-investidores",
          },
          {
            label: "E-social",
            to: "/e-social",
          },
          {
            label: "IRPF",
            to: "/declar-irpf",
          },
          {
            label: "CPF irregular",
            to: "/cpf",
          },
          {
            label: "GCAP",
            to: "/gcap",
          },
        ],
      },
    },
  },
  {
    label: "Para seu Negócio",
    to: "/",
    options: {
      companies: {
        label: "Pessoa Juridica",
        items: [
          {
            label: "MEI",
            to: "/mei",
          },
          {
            label: "SIMPLES NACIONAL E LUCRO PRESUMIDO",
            to: "/contabilidade",
          },
          {
            label: "regularização de cnpj",
            to: "/regularizacao-de-cnpj",
          },
          {
            label: "Processos societários",
            to: "/processos-societarios",
          },
        ],
      },
    },
  },
  {
    label: "Tradertax",
    to: "#invest-time",
  },
  // {
  //   label: "PORTAL DO CLIENTE",
  //   to: "/process",
  // },
];

export default links;
