import { css } from "@emotion/react";
import { withBaseColors } from "./colors";
import { IBaseColor, IBaseTheme } from "./types";

const colors = withBaseColors((baseThemeColors: IBaseColor) => baseThemeColors);

const baseTheme: IBaseTheme = {
  fonts: {
    inter: "Inter, sans-serif",
  },
  container: css`
    width: 100%;
    max-width: 1638px;
    margin: 0 auto;
    padding: 0 min(2rem, 6vw);

    @media only screen and (max-width: 520px) {
      padding: 0 2.5rem;
    }
  `,
  colors,
  carousel: {
    breakResolution: 1076,
  },
  breakpoints: {
    tablet: 960,
    phone: 520,
  },
};

export default baseTheme;
