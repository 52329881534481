import React from "react";
import { Link } from "gatsby";
import Reveal from "react-reveal/Reveal";
import {
  FooterWrapper,
  FooterContainer,
  FooterRect,
  FooterColumn,
  FooterTermsAndService,
  FooterSocial,
} from "./styled";
import RectIcon from "@/assets/svg/rect.svg";
import Logo from "@/assets/svg/LEAN-logo.svg";
import Typography from "@/components/Typography";

import InstagramIcon from "@/assets/svg/instagram.svg";

function Footer() {
  return (
    <FooterWrapper>
      <FooterRect>
        <Reveal effect="visibleRect">
          <RectIcon />
        </Reveal>
      </FooterRect>

      <FooterContainer>
        <Logo />

        <FooterColumn bordered>
          <header>
            <Typography variant="small" weight={700} uppercase>
              Sobre Nós
            </Typography>
          </header>
          <ul>
            <li>
              <Typography variant="small" as={Link} to="/#sobre-nos">
                A Lean
              </Typography>
            </li>
            <li>
              <Typography variant="small" as={Link} to="/#invest-time">
                TRADERTAX
              </Typography>
            </li>
          </ul>
        </FooterColumn>

        <FooterColumn bordered>
          <header>
            <Typography variant="small" weight={700} uppercase>
              Serviços
            </Typography>
          </header>

          <ul>
            <li>
              <Typography variant="small" weight={700} uppercase>
                Para empresas
              </Typography>
            </li>
            <li>
              <Typography variant="small" as={Link} to="/mei">
                MEI
              </Typography>
            </li>
            <li>
              <Typography variant="small" as={Link} to="/contabilidade">
                Simples nacional e lucro presumido
              </Typography>
            </li>
            <li>
              <Typography variant="small" as={Link} to="/regularizacao-de-cnpj">
                Regularização de CNPJ
              </Typography>
            </li>
            <li>
              <Typography variant="small" as={Link} to="/processos-societarios">
                Processos Societários
              </Typography>
            </li>
          </ul>

          <ul>
            <li>
              <Typography variant="small" weight={700} uppercase>
                Para Você
              </Typography>
            </li>
            <li>
              <Typography variant="small" as={Link} to="/para-investidores">
                Contabilidade para Investidores
              </Typography>
            </li>
            <li>
              <Typography variant="small" as={Link} to="/e-social">
                E-SOCIAL
              </Typography>
            </li>
            <li>
              <Typography variant="small" as={Link} to="/declar-irpf">
                IPRF
              </Typography>
            </li>
            <li>
              <Typography variant="small" as={Link} to="/cpf">
                CPF IRREGULAR
              </Typography>
            </li>
            <li>
              <Typography variant="small" as={Link} to="/gcap">
                GCAP
              </Typography>
            </li>
          </ul>
        </FooterColumn>

        <FooterColumn>
          <header>
            <Typography variant="small" weight={700} uppercase>
              Saiba Mais
            </Typography>
          </header>

          <ul>
            <li>
              <Typography
                variant="small"
                as={Link}
                to="https://wa.me/5581994019577"
              >
                Whatsapp
              </Typography>
            </li>
            <li>
              <Typography
                variant="small"
                as={Link}
                to="https://www.instagram.com/leancontabilidade"
              >
                Instagram
              </Typography>
            </li>
          </ul>
        </FooterColumn>
      </FooterContainer>

      <FooterSocial>
        <InstagramIcon />
      </FooterSocial>

      <FooterTermsAndService color="light" variant="small" uppercase>
        Lean Serviços de contabilidade LTDA - CNPJ 34.479.161/0001-21{" "}
        <Typography as="span" color="light" variant="small" uppercase>
          @ LEAN Todos os direitos reservados
        </Typography>
      </FooterTermsAndService>
    </FooterWrapper>
  );
}

export default Footer;
