import styled from "@emotion/styled";

// TODO: move colors to mobile menu theme colors
export const MenuIconWrapper = styled.div`
  cursor: pointer;
`;

export const MobileModalWrapper = styled.section<{ visible?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: #161c2d;
  padding: 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 154px auto 74px;
  transition: all ease-out 0.3s;

  ${({ visible }) =>
    visible
      ? `
    opacity: 1;
    visibility: visible;

    svg:first-of-type path:last-child {
      stroke-width: 2px;
      stroke-dasharray: 412px;
      stroke-dashoffset: 0;
      transition-delay: 0.3s;
    }
  `
      : `
    opacity: 0;
    visibility: hidden;
  `}
`;

export const MobileModalHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;

  svg:first-of-type path:last-child {
    transition: all ease 1s;
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
    stroke-dasharray: 412px;
    stroke-dashoffset: 422px;
  }
`;

export const MobileMenuContainer = styled.ul`
  display: flex;
  flex-direction: column;
  grid-row-gap: 2rem;
  justify-content: center;

  li {
    width: 100%;
    text-align: center;
  }

  a {
    color: #fff;
    text-decoration: none;
    font-size: 34px;
  }
`;

export const MobileMenuFooter = styled.footer`
  text-align: center;

  a {
    background: #fff;
  }
`;
