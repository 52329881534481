import baseTheme from "../base";
import { withBaseColors } from "../base/colors";
import { IBaseTheme, IThemeColors } from "../base/types";

const colors = withBaseColors(
  (themeBaseColors: IThemeColors) => themeBaseColors
);

const lightTheme: IBaseTheme = {
  ...baseTheme,
  colors,
};

export default lightTheme;
