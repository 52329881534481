import styled from "@emotion/styled";
import Logo from "@/assets/svg/LEAN-logo.svg";

export const HeaderLogo = styled(Logo)``;

export const HeaderWrapper = styled.header<{ bg?: string }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.5rem 0 1rem;
  background: ${({ bg }) => bg || `var(--header-theme)`};
  z-index: 8;
  transition: all 0.3s ease-out;

  > div > a > svg,
  > div > div > svg {
    color: var(--header-logo-theme);
    transition: all 0.3s ease-out;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    padding-top: 4rem;
    position: fixed;

    > div > a > svg,
    > div > div > svg {
      width: 98px;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.container};
`;
