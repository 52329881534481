import styled from "@emotion/styled";
import { sharedRectStyles, rectAnimation } from "@/theme/base/utils";
import Typography from "@/components/Typography";

export const FooterWrapper = styled.footer`
  min-height: 120vh;
  background: ${({ theme }) => theme.colors.footer.background};
  position: relative;
  overflow: hidden;

  p,
  a {
    text-transform: uppercase;
    font-size: 10px;
    text-decoration: none;
  }

  p,
  a,
  span,
  svg {
    color: ${({ theme }) => theme.colors.footer.color};
  }

  svg {
    color: ${({ theme }) => theme.colors.footer.color};
  }

  @media only screen and (max-width: 1230px) {
    padding-bottom: 2rem;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    padding: 8rem 0 4rem;
  }
`;

export const FooterContainer = styled.div`
  ${({ theme }) => theme.container}
  display: grid;
  grid-template-columns: 263px repeat(4, auto) repeat(3, 173px);
  padding-top: 106px;

  @media only screen and (max-width: 1230px) {
    grid-template-columns: auto;

    > svg {
      margin-bottom: 6rem;
    }
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    > svg {
      margin-bottom: 8rem;
    }
  }
`;

export const FooterRect = styled.figure`
  position: absolute;
  bottom: -14rem;
  right: -3rem;
  width: 60%;
  margin: 0;
  max-width: 1262px;
  color: ${({ theme }) => theme.colors.light};

  path:first-of-type {
    ${sharedRectStyles}
  }

  .visibleRect path:first-of-type {
    animation: ${rectAnimation} 3s ease-out forwards;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    display: none;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    display: block;
    bottom: -20rem;
    right: -137px;
    width: 774px;
  }
`;

export const FooterColumn = styled.article<{ bordered?: boolean }>`
  color: ${({ theme }) => theme.colors.footer.color};
  padding-left: 10px;

  ul {
    margin-top: 2rem;
  }

  a:hover {
    text-decoration: underline;
  }

  header p {
    font-size: 11px;
  }

  ${({ bordered, theme }) =>
    bordered && `border-right: solid 1px ${theme.colors.footer.color};`}

  @media only screen and (max-width: 1230px) {
    border: none;
    border-left: solid 1px ${({ theme }) => theme.colors.footer.color};
    padding-bottom: 2rem;
    margin-left: 1rem;
    padding-left: 1rem;

    &:last-child {
      padding-bottom: 0;
    }

    p,
    a,
    header p {
      font-size: 16px;
    }

    li {
      padding-bottom: 0.5rem;
    }
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}px) {
    p,
    a,
    header p {
      font-size: 16px;
    }
  }
`;

export const FooterTermsAndService = styled(Typography)`
  ${({ theme }) => theme.container}

  padding-top: 27rem;
  display: block;

  &&,
  span {
    font-size: 11px;
  }

  span {
    padding-left: 2rem;
  }

  @media only screen and (max-width: 1230px) {
    display: block;
    text-align: center;
    padding-top: 3rem;
    font-size: 16px;

    width: 70%;
    text-align: center;

    span {
      display: block;
      text-align: center;
      padding: 2rem 0 0;
    }
  }
`;

export const FooterSocial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column-gap: 1rem;
  margin-top: 14rem;

  @media only screen and (min-width: 1230px) {
    display: none;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.phone}px) {
    margin-top: 5rem;
  }
`;
