import { useEffect, useState, useCallback } from "react";

const useResolution = (resolution: number) => {
  const [betweenResolution, setBetweenResolution] = useState(false);

  const handleResolutionChange = useCallback(() => {
    setBetweenResolution(window.innerWidth <= resolution);
  }, []);

  useEffect(() => {
    handleResolutionChange();

    window.addEventListener("resize", handleResolutionChange);

    return () => {
      window.removeEventListener("resize", handleResolutionChange);
    };
  }, [handleResolutionChange]);

  return betweenResolution;
};

export default useResolution;
