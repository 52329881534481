import { IButtonWrapperProps } from "./types";

export const getVariantStyles = ({
  color = "primary",
  theme,
  outlined,
}: IButtonWrapperProps) => {
  const VARIANTS_MAPPER = {
    primary: `
    ${
      outlined
        ? `
            background: transparent;
            border: solid 2px ${theme?.colors.button.primaryBackground};
            color: ${theme?.colors.button.primaryBackground};
          `
        : `
            background: ${theme?.colors.button.primaryBackground};
            color: ${theme?.colors.button.primary};
        `
    }
      
    `,
    secondary: `
      background: ${theme?.colors.button.secondaryBackground};
      color: ${theme?.colors.button.secondary};   
    `,
    light: `
      background: ${theme?.colors.button.lightBackground};
      color: ${theme?.colors.button.light};
    `,
  };

  return VARIANTS_MAPPER[color];
};
