import { IThemeColors, IBaseColor, IBaseTheme } from "../base/types";
import baseTheme from "../base";
import { withBaseColors } from "../base/colors";

const baseColors: IBaseColor = {
  light: "#E83E8C",
  primary: "#fff",
  secondary: "#E83E8C",
};

const colors = withBaseColors((themeBaseColors: IThemeColors) => {
  return {
    ...themeBaseColors,
    logo: {
      primary: baseColors.light,
    },
    navbar: {
      background: baseColors.primary,
      border: "#F0F2F4",
      item: baseTheme.colors.primary,
    },
    topic: {
      text: "#2b354f",
    },
    plans: {
      primary: "#2b354f",
    },
    contact: {
      header: {
        links: baseColors.primary,
        background: baseColors.light,
        buttonColor: baseTheme.colors.primary,
        buttonBg: baseColors.primary,
      },
      text: baseColors.primary,
    },
    fonts: {
      primary: baseColors.primary,
      stone: "#bebebe",
      light: baseColors.light,
    },
    box: {
      background: baseColors.primary,
      color: baseColors.light,
      hover: {
        background: "#F9FBFD",
      },
    },
    footer: {
      background: baseColors.primary,
      color: baseTheme.colors.primary,
    },
    button: {
      // this will variy according to the different themes
      primary: baseColors.primary,
      primaryBackground: baseTheme.colors.primary,
      secondary: baseColors.primary,
      secondaryBackground: baseColors.light,
      lightBackground: baseTheme.colors.primary,
      light: baseColors.primary,
    },
  };
}, baseColors as IThemeColors);

const pinkTheme: IBaseTheme = {
  ...baseTheme,
  colors,
};

export default pinkTheme;
