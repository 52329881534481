import { css, Global, useTheme } from "@emotion/react";
import React from "react";
import globals from "@/theme/globals";
import Header from "./components/Header";
import { LayoutWrapper } from "./styled";
import Footer from "./components/Footer";

interface ILayoutProps {
  children: React.ReactNode;
  headerBg?: string;
}

function Layout({ children, headerBg }: ILayoutProps) {
  const currentTheme = useTheme();

  return (
    <LayoutWrapper className="layout">
      <Global styles={globals} />
      <Global
        styles={css`
          :root {
            --header-theme: ${currentTheme.colors.light};
            --header-logo-theme: ${currentTheme.colors.primary};
          }
        `}
      />

      <Header bg={headerBg} />

      {children}

      <Footer />
    </LayoutWrapper>
  );
}

export default Layout;
