import { TypographyBaseProps } from "./types";

export const getVariantStyles = ({
  variant = "body1",
  theme,
}: TypographyBaseProps) => {
  const VARIANTS_MAPPER = {
    h1: `
      font-weight: 700;
      line-height: min(72px, 5vw);
      font-size: min(66px,4vw);

      @media only screen and (max-width: ${theme.breakpoints.tablet}px) {
        font-size: max(34px,6vw);
        line-height: max(40px, 8vw);
      }
    `,
    h2: `
      font-size: 64px;
      font-weight: 700;
      line-height: 72px;
    `,
    h3: `
      font-size: min(60px, 4vw);
      font-weight: 700;
      line-height: min(60px, 5vw);

      @media only screen and (max-width: ${theme.breakpoints.tablet}px) {
        font-size: max(40px, 7vw);
        line-height: 62px;
      }

      @media only screen and (max-width: ${theme.breakpoints.phone}px) {
        font-size: 38px;
        line-height: 50px;
      }
    `,
    h4: `
      font-size: 36px;
      font-weight: 700;
      line-height: 32px;
    `,
    h5: `
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
    `,
    h6: `
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;


      @media only screen and (max-width: ${theme.breakpoints.phone}px) {
        font-size: 18px;
        line-height: 24px;
      }
    `,
    body1: `
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;


      @media only screen and (max-width: ${theme.breakpoints.phone}px) {
        font-size: 16px;
        line-height: 34px;
      }
    `,
    body2: `
      font-weight: 700;
      font-size: 18px;
      line-height: 30px;


      @media only screen and (max-width: ${theme.breakpoints.phone}px) {
        font-size: 16px;
        line-height: 34px;
      }
    `,
    small: `
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    `,
  };

  return VARIANTS_MAPPER[variant];
};
