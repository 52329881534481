import React from "react";
import { Link } from "gatsby";
import Typography from "@/components/Typography";
import { NavigationDropdown, NavigationDropdownItem } from "./styled";

function Dropdown({ options }: any) {
  return (
    <NavigationDropdown>
      <div>
        {Object.keys(options).map((key) => (
          <ul key={key}>
            <NavigationDropdownItem key={key}>
              <Typography uppercase weight={700} variant="small" color="stone">
                {options[key].label}
              </Typography>
            </NavigationDropdownItem>

            {options[key].items.map(
              ({ label: itemLabel, to: itemLink }: any) => (
                <NavigationDropdownItem key={itemLink}>
                  <Typography
                    uppercase
                    weight={700}
                    variant="small"
                    as={Link}
                    to={itemLink}
                  >
                    {itemLabel}
                  </Typography>
                </NavigationDropdownItem>
              )
            )}
          </ul>
        ))}
      </div>
    </NavigationDropdown>
  );
}

export default Dropdown;
