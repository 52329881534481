import { Link } from "gatsby";
import React from "react";
import Navbar from "../Navbar";

import { HeaderLogo, HeaderWrapper, HeaderContainer } from "./styled";

interface IHeaderProps {
  bg?: string;
}

function Header({ bg }: IHeaderProps) {
  return (
    <HeaderWrapper bg={bg} className="header">
      <HeaderContainer>
        <Link to="/">
          <HeaderLogo />
        </Link>

        <Navbar />
      </HeaderContainer>
    </HeaderWrapper>
  );
}

export default Header;
