exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contabilidade-mdx": () => import("./../../../src/pages/contabilidade.mdx" /* webpackChunkName: "component---src-pages-contabilidade-mdx" */),
  "component---src-pages-cpf-mdx": () => import("./../../../src/pages/cpf.mdx" /* webpackChunkName: "component---src-pages-cpf-mdx" */),
  "component---src-pages-declar-irpf-mdx": () => import("./../../../src/pages/declar-irpf.mdx" /* webpackChunkName: "component---src-pages-declar-irpf-mdx" */),
  "component---src-pages-e-social-mdx": () => import("./../../../src/pages/e-social.mdx" /* webpackChunkName: "component---src-pages-e-social-mdx" */),
  "component---src-pages-gcap-mdx": () => import("./../../../src/pages/gcap.mdx" /* webpackChunkName: "component---src-pages-gcap-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mei-mdx": () => import("./../../../src/pages/mei.mdx" /* webpackChunkName: "component---src-pages-mei-mdx" */),
  "component---src-pages-para-investidores-mdx": () => import("./../../../src/pages/para-investidores.mdx" /* webpackChunkName: "component---src-pages-para-investidores-mdx" */),
  "component---src-pages-processos-societarios-mdx": () => import("./../../../src/pages/processos-societarios.mdx" /* webpackChunkName: "component---src-pages-processos-societarios-mdx" */),
  "component---src-pages-regularizacao-de-cnpj-mdx": () => import("./../../../src/pages/regularizacao-de-cnpj.mdx" /* webpackChunkName: "component---src-pages-regularizacao-de-cnpj-mdx" */),
  "component---src-pages-termos-e-condicoes-tsx": () => import("./../../../src/pages/termos-e-condicoes.tsx" /* webpackChunkName: "component---src-pages-termos-e-condicoes-tsx" */)
}

