import { keyframes, css } from "@emotion/react";
import { TSectionNavbarFn, TNavbarColors, TSectionName } from "./types";
import { IBaseTheme } from "@/theme/base/types";

export const sharedRectStyles = css`
  fill: none;
  stroke: currentColor;
  stroke-dashoffset: 3245px;
  stroke-dasharray: 3245px;
  stroke-width: 6px;
`;

export const rectAnimation = keyframes`
  0% {
      stroke-dashoffset: 3245px;
      stroke-dasharray: 3245px;
  }
  35% {
      stroke-dashoffset: 1245px;
      stroke-dasharray: 3245px;
  } 
  100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 3245px;
  }
`;

interface ISectionsNavbarColors {
  transparent: TSectionNavbarFn;
  light: TSectionNavbarFn;
  dark: TSectionNavbarFn;
}

const sectionsNavbarColors: ISectionsNavbarColors = {
  transparent: (currentTheme: IBaseTheme): TNavbarColors =>
    currentTheme.colors.contact.header,
  light: (currentTheme: IBaseTheme): TNavbarColors => ({
    background: currentTheme.colors.light,
    links: currentTheme.colors.primary,
    buttonColor: currentTheme.colors.light,
    buttonBg: currentTheme.colors.primary,
  }),
  dark: (currentTheme: IBaseTheme): TNavbarColors => ({
    background: currentTheme.colors.primary,
    links: currentTheme.colors.light,
    buttonColor: currentTheme.colors.primary,
    buttonBg: currentTheme.colors.light,
  }),
};

export const setHeaderColors =
  (currentTheme: IBaseTheme, sectionName: TSectionName) =>
  (root: HTMLElement) => {
    const { background, links, buttonColor, buttonBg } =
      sectionsNavbarColors[sectionName](currentTheme);

    root.style.setProperty("--header-theme", background);
    root.style.setProperty("--header-logo-theme", links);
    root.style.setProperty("--header-button-color", buttonColor);
    root.style.setProperty("--header-button-background", buttonBg);
  };
