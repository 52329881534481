import React from "react";
import useResolution from "@/hooks/useResolution";
import baseTheme from "@/theme/base";

import Desktop from "./variants/Desktop";
import Mobile from "./variants/Mobile";

function Navbar() {
  const isTablet = useResolution(baseTheme.breakpoints.tablet);

  return isTablet ? <Mobile /> : <Desktop />;
}

export default Navbar;
