import React from "react";
import { Link } from "gatsby";
import Typography from "@/components/Typography";
import Button from "@/components/Button/Button";
import links from "../../links";
import { NavigationContainer, NavigationItem } from "./styled";
import Dropdown from "./Dropdown";

import CallNowIcon from "@/assets/svg/call-now.svg";
import useNavigation from "../../hooks/useNavigation";

function Desktop() {
  const onNavigate = useNavigation();

  return (
    <NavigationContainer role="navigation">
      {links.map(({ label, options, to, offset }: any) => (
        <NavigationItem key={to}>
          <Typography
            onClick={onNavigate(to, offset)}
            uppercase
            weight={700}
            variant="small"
            as={Link}
            to={to}
          >
            {label}
          </Typography>

          {options && <Dropdown options={options} />}
        </NavigationItem>
      ))}

      <Button as={Link} onClick={onNavigate("#contact", -100)} to="/">
        <CallNowIcon /> Ligue Agora
      </Button>
    </NavigationContainer>
  );
}

export default Desktop;
