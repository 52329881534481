import isPropValid from "@emotion/is-prop-valid";
import styled from "@emotion/styled";
import { Link } from "gatsby";
import React from "react";
import Typography from "../Typography";
import { IButtonProps, IButtonWrapperProps } from "./types";
import { getVariantStyles } from "./variants";

const ButtonWrapper = styled(Typography, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "color",
})<IButtonWrapperProps>`
  padding: 20px 32px;
  border: none;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  grid-column-gap: 0.5rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  svg {
    transition: all ease-out 0.3s;
  }

  &:hover svg {
    transform: scale(1.2);
  }

  ${getVariantStyles}
`;

function Button({
  as = Link,
  to,
  children,
  outlined,
  variant,
  onClick,
}: IButtonProps) {
  return (
    <ButtonWrapper
      outlined={outlined}
      to={to}
      as={as}
      onClick={onClick}
      color={variant}
    >
      {children}
    </ButtonWrapper>
  );
}

export default Button;
